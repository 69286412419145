
import { defineComponent, reactive, ref } from "vue";
import axios from "axios";
import { message } from "ant-design-vue";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  PaperClipOutlined,
} from "@ant-design/icons-vue";
import { FileItem } from "@/API/types";
import { useStore } from "vuex";
import useModel from "@/hooks/useModel";
import downloadFile from "@/utils/payment/downloadFile";
import { checkMappingFile, reuploadDocFile, checkDocData, uploadDocData, docIsSplit, getDocSplitNum } from "@/API/rv/rvSplitTask";
type StatusType =
  | "start"
  | "beforeUpload"
  | "mapChecked"
  | "beforeDocUpload"
  | "docChecked"
  | "splited"
export default defineComponent({
  name: "App",
  props: {
    category: {
      type: String,
      default: "1",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const { state } = useStore();
    const _visible = useModel(props, "visible");
    const handleDownloadTemplate = () => {
      const params = {
        url: `/rvapi/task/docData/download/mapping/template`,
        method: "post",
      };
      downloadFile(params);
    };
    const uploadStatus = ref<StatusType>("start");
    // #region [map]
    // 上传相关

    const curUploadFile = ref<object>({});
    const uploadFileSize = ref<string>("");

    const mappingFile = ref<FileItem|undefined>()
    const documentFiles = ref<FileItem[]>([])
    const uploadInfo = reactive({
      current: 0,
      total: 1,
      percent: 0,
      size: ''
    })

    const resetUploadInfo = () => {
      Object.assign(uploadInfo, {
        current: 0,
        total: 1,
        percent: 0,
        size: ''
      })
    }

    // 上传前校验 mapping文件
    const beforeUpload = (file: FileItem) => {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXlsx) {
        message.error("Only xlsx format files can be uploaded!");
      }
      const maxFileSize = 30;
      const isLt30M = file.size / 1024 / 1024 <= maxFileSize;
      if (!isLt30M) {
        message.error(
          `The file size should not be larger than ${maxFileSize}MB!`
        );
      }
      return isXlsx && isLt30M;
    };
    // 上传 获取mapping formData
    const uploadRequest = (source: any) => {
      mappingFile.value = source.file
      resetUploadInfo()
      uploadInfo.total = 1
      uploadInfo.size = source.file.size / 1024 > 1024
        ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
        : `${Math.floor(source.file.size / 1024)} KB`
      uploadStatus.value = "beforeUpload";
    };
    // 校验mapping上传文件
    const checkUploadFile = async () => {
      try {
        const result = await checkMappingFile(mappingFile.value as unknown as Blob, {
          onUploadProgress: (progressEvent: any) => {
            if (progressEvent.lengthComputable) {
              const percent = ((progressEvent.loaded / progressEvent.total) * 100) | 0
              uploadInfo.percent = percent
              uploadInfo.current = Math.floor(percent * uploadInfo.total)
            }
          }
        })
        if (result === 1) {
          uploadStatus.value = "mapChecked";
          resetUploadInfo()
        } else {
          message.error('Document list error')
        }
      } catch {
        uploadStatus.value = "beforeUpload";
      }
    };
    // #endregion

    // #region [doc]
    const docCurUploadFile = ref<object>({});
    const docUploadFileSize = ref<string>("");
    
    // 上传前校验document文件
    const docBeforeUpload = async (file: FileItem, fileList: FileItem[]) => {
      const isEnableType = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/x-zip-compressed'
      ].includes(file.type as string)
      if (!isEnableType) {
        message.error('File type error');
      }
      const maxFileSize = 30;
      const isLt30M = file.size / 1024 / 1024 < maxFileSize;
      if (!isLt30M) {
        message.error(`The file size should not be larger than ${maxFileSize}MB!`);
      }
      if (isLt30M && isEnableType) {
        documentFiles.value.push(file);
      } else {
        documentFiles.value = [];
      }
      if (documentFiles.value.length === fileList.length) {
        resetUploadInfo();
        uploadInfo.total = fileList.length;
          const size = fileList.reduce((size: number, file: FileItem) => {
            return size += file.size
          }, 0)
          uploadInfo.size = size / 1024 > 1024
            ? `${(size / 1024 / 1024).toFixed(1)} MB`
            : `${Math.floor(size / 1024)} KB`
          uploadStatus.value = 'beforeDocUpload';
      }
      return isLt30M && isEnableType;
    };

    // #endregion
    const dataErrorModalvisible = ref(false);
    const dataErrorColumns = [
      {
        title: 'Dealer Code',
        dataIndex: 'dealerCode'
      },
      {
        title: 'SWT Code',
        dataIndex: 'swtCode'
      },
      {
        title: 'Document Name',
        dataIndex: 'documentName'
      }
    ]
    const dataError = ref([])

    const handleCheckDocData = async () => {
      const result = await checkDocData(props.id, mappingFile.value as unknown as Blob, documentFiles.value as unknown as Blob[], {
        onUploadProgress: (progressEvent: any) => {
          if (progressEvent.lengthComputable) {
            const percent = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
            uploadInfo.percent = percent;
            uploadInfo.current = Math.floor(percent * uploadInfo.total);
          }
        }
      })
      if (result.code === '0' && result.data.length === 0) {
        uploadStatus.value = 'docChecked'
        resetUploadInfo();
      } else {
        dataError.value = result.data
        dataErrorModalvisible.value = true
        documentFiles.value = []
        uploadStatus.value = 'mapChecked'
      }
    }

    // 判断文件是否可以拆分
    const splitModalvisible = ref(false);
    const splitDealerNum = ref();
    const checkSplit = async () => {
      const result = await docIsSplit(mappingFile.value as unknown as  Blob);
      if (result.code === '0') {
        const data = await getDocSplitNum(props.id, mappingFile.value as unknown as  Blob)
        splitDealerNum.value = data;
        splitModalvisible.value = true;
      } else {
        message.error("error");
      }
    };
    const uploadFile = () => {
      uploadDocData(props.id, mappingFile.value as unknown as Blob, documentFiles.value as unknown as Blob[], {
        onUploadProgress: (progressEvent: any) => {
          if (progressEvent.lengthComputable) {
            const percent = ((progressEvent.loaded / progressEvent.total) * 100) | 0
            uploadInfo.percent = percent
            uploadInfo.current = Math.floor(percent * uploadInfo.total)
          }
        }
      }).then((res) => {
        uploadStatus.value = "splited";
      }).catch(() => {
        uploadStatus.value = "start";
      });
    };
    const handleUpload = () => {
      reuploadDocFile(props.id).then(() => {
        splitModalvisible.value = false;
        uploadFile();
      });
    };
    const handleNext = () => {
      _visible.value = false;
      context.emit("next");
    };
    return {
      _visible,
      uploadStatus,
      mappingFile,
      documentFiles,
      uploadInfo,
      curUploadFile,
      uploadFileSize,
      beforeUpload,
      uploadRequest,
      checkUploadFile,
      docCurUploadFile,
      docUploadFileSize,
      docBeforeUpload,
      handleCheckDocData,
      checkSplit,
      splitModalvisible,
      splitDealerNum,
      handleUpload,
      uploadFile,
      handleDownloadTemplate,
      handleNext,
      dataErrorModalvisible,
      dataErrorColumns,
      dataError,
    };
  },
  components: {
    CheckCircleOutlined,
    PaperClipOutlined
  },
});
