import { ref, reactive, computed, toRaw, h } from "vue";
import { useRoute } from "vue-router";
import { getTaskDetailList } from "@/API/rv/rvSplitTask";
import { getAllBu } from "@/API/dealerMaster";
import { TaskCategoryList } from "@/views/RetailValidation/Communication/dictionary";

const getMap = (list: any[]) => {
  return list?.reduce((prev, cur) => {
    prev[cur.value] = cur.name;
    return prev;
  }, {});
};

const useTaskDistributionOverview = () => {
  const route = useRoute();
  const { category, id, name } = route.query;
  const originColumn = ref([
    { title: "Task Name", dataIndex: "taskName", width: 168 },
    {
      title: "Task Category",
      dataIndex: "taskCategory",
      width: 168,
      customRender: ({ text }: any) => {
        return h("span", getMap(TaskCategoryList)[text]);
      },
    },
    { title: "Task Description", dataIndex: "taskDescription", width: 168 },
    { title: "Dealer Code", dataIndex: "dealerCode", width: 168 },
    { title: "SWT Code", dataIndex: "swtCode", width: 168 },
    { title: "Dealer Name CN", dataIndex: "dealerNameCN", width: 168 },
    // { title: "Sub Region", dataIndex: "subRegion", width: 168 },
    { title: "If receive data/document", dataIndex: "isReceive",slots: {customRender: 'isReceive'}, width: 168 },
    { title: "Dealer Feedback", dataIndex: "feedbackDealer", slots: {customRender: 'feedback'}, width: 168 },
  ]);
  // 根据query 过滤column
  const column = computed(() => {
    return originColumn.value.filter((item: any) => {
      return category === "2" || item.dataIndex !== "taskDescription";
    });
  });
  const query = reactive({
    bu: undefined,
    dealerCode: "",
    dealerNameCn: "",
    headerId: id,
    subRegion: undefined,
    swtCode: "",
  });
  const dataRes = ref();
  const data = computed(() => {
    return dataRes.value ? dataRes.value : [];
  });
  const fetchTableData = () => {
    const params = { ...toRaw(query) };
    Object.keys(params).forEach((key) => {
      if (params[key] === undefined) {
        params[key] = "";
      }
    });
    return getTaskDetailList(params).then((res: any) => {
      dataRes.value = res;
    });
  };
  const buRes = ref();
  const buList = computed(() => {
    return buRes.value ? buRes.value : [];
  });
  const fetchBulist = () => {
    getAllBu().then((res: any) => {
      buRes.value = res.filter((item: {nameEn: string}) => item.nameEn !== 'Companycar')
        .map((item: {nameEn: string}) => {
          if (item.nameEn === 'MBPC') {
            item.nameEn = 'MB'
          }
          return item
        });
    });
  };
  const subRegionRes = ref();
  const subRegionList = computed(() => {
    if (subRegionRes.value && subRegionRes.value[0].regionDto) {
      return subRegionRes.value[0].regionDto;
    } else {
      return [];
    }
  });

  return {
    query,
    data,
    column,
    fetchTableData,
    buList,
    fetchBulist,
    subRegionRes,
    subRegionList,
    id, // headerId
    category,  // 任务类型
    name // 任务名称
  };
};
export default useTaskDistributionOverview;
