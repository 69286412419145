import { AxiosRequestConfig } from 'axios';
import { fetch } from '@/API/index';
import { message } from 'ant-design-vue';

interface Response {
    data: {
        docName: string;
        docType: string;
        downloadUrl: string;
        validPeriod: string;
    };
    [key: string]: any;
}

/**
 *
 * @param config 请参考 axios AxiosRequestConfig 声明
 */
export const s3Download = (config: AxiosRequestConfig) => {
    return new Promise<void>((resolve, reject) => {
        fetch<Response>(config).then(res => {
            const downloadElement = document.createElement('a')
            downloadElement.href = res.data.downloadUrl;
            downloadElement.download = res.data.docName;
            downloadElement.click();
            resolve();
        }).catch(e => {
            if (e.config.headers['code-handler'] === 'ignore') {
                switch (e.response.data.code) {
                    case '90012':
                      message.warning(e.response.data.message);
                      break;
                    default:
                      message.error(e.response.data.message);
                      break;
                }
            }
            reject(e);
        })
    })
}
