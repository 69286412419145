
import { defineComponent, ref, toRefs, toRaw } from "vue";
import { useRoute } from "vue-router";
import { message } from "ant-design-vue";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import useTaskDistributionDetail from "@/hooks/rv/communication/useTaskDistributionDetail";
import { getTaskDetailView, taskWait, taskRelease } from "@/API/rv/rvSplitTask";
import downloadFile from "@/utils/payment/downloadFile";
import UploadDataFile from "@/views/RetailValidation/Communication/TaskDistribution/Detail/uploadDataFile.vue";
import UploadDocumentFile from "@/views/RetailValidation/Communication/TaskDistribution/Detail/uploadDocumentFile.vue";
import { s3Download } from "@/utils/s3Download";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const {
      query,
      data,
      column,
      fetchTableData,
      buList,
      fetchBulist,
      subRegionRes,
      subRegionList,
      id,
      category,
      name,
    } = useTaskDistributionDetail();

    const route = useRoute()

    const status = ref(route.query.status)

    const search = () => {
      fetchTableData();
    };
    const init = () => {
      fetchBulist();
      search();
    };
    init();
    const handleBuChange = (val: any) => {
      query.subRegion = undefined;
      if (val) {
        subRegionRes.value = buList.value?.filter((item: any) => {
          return item.nameEn === val;
        });
      } else {
        subRegionRes.value = null;
      }
    };
    const feadbackModalvisible = ref(false);
    const feadbackModalContent = ref();
    const handleView = (record: any) => {
      if (record.feedback === '2') {
        return
      }
      getTaskDetailView(record.id).then((res: any) => {
        feadbackModalContent.value = res;
        feadbackModalvisible.value = true;
      });
    };
    const handleDownload = () => {
      s3Download({
        url: `/rvapi/task/docData/download/doc/docList?headerId=${id}`,
        method: "POST",
      })
    };
    const handleExport = () => {
      let params = null;
      const _query = { ...toRaw(query) };
      Object.keys(_query).forEach((key) => {
        if (_query[key] === undefined) {
          _query[key] = "";
        }
      });
      if (category === "1") {
        params = {
          url: `/rvapi/task/docData/download/data/taskDetail`,
          method: "post",
          params: _query,
        };
      } else {
        params = {
          url: `/rvapi/task/docData/download/doc/taskDetail`,
          method: "post",
          params: _query,
        };
      }
      downloadFile(params);
    };
    const uploadDataVisible = ref(false);
    const uploadDocumentVisible = ref(false);
    const handleUploadShow = () => {
      if (category === "1") {
        uploadDataVisible.value = true;
      } else {
        uploadDocumentVisible.value = true;
      }
    };
    const handleWait = () => {
      if (status.value === '1') {
        status.value = '2'
      }
      taskWait(id).then(() => {
        fetchTableData();
      });
    };
    const handleRelease = () => {
      taskRelease(id).then(() => {
        message.success("Release successfully");
        fetchTableData();
      });
    };
    return {
      ...toRefs(query),
      column,
      data,
      buList,
      handleBuChange,
      subRegionList,
      search,
      handleView,
      feadbackModalvisible,
      feadbackModalContent,
      status,
      category,
      name,
      id,
      handleDownload,
      handleExport,
      handleUploadShow,
      uploadDataVisible,
      uploadDocumentVisible,
      handleWait,
      handleRelease,
    };
  },
  components: {
    TableWrapper,
    UploadDataFile,
    UploadDocumentFile,
  },
});
