
import { defineComponent, computed, ref } from "vue";
import axios from "axios";
import { message } from "ant-design-vue";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { FileItem } from "@/API/types";
import { useStore } from "vuex";
import useModel from "@/hooks/useModel";
import downloadFile from "@/utils/payment/downloadFile";
import { reuploadFile } from "@/API/rv/rvSplitTask";
type StatusType = "start" | "beforeUpload" | "checked" | "splited" | "imported";
export default defineComponent({
  name: "App",
  props: {
    category: {
      type: String,
      default: "1",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const { state } = useStore();
    const _visible = useModel(props, "visible");
    const handleDownloadTemplate = () => {
      const params = {
        url: `/rvapi/task/docData/download/data/template`,
        method: "post",
      };
      downloadFile(params);
    };
    // 上传相关
    const uploadStatus = ref<StatusType>("start");
    const curUploadFile = ref<object>({});
    const uploadFileSize = ref<string>("");
    // 上传前校验
    const beforeUpload = (file: FileItem) => {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXlsx) {
        message.error("Only xlsx format files can be uploaded!");
      }
      const maxFileSize = 30
      const isLt30M = file.size / 1024 / 1024 <= maxFileSize;
      if (!isLt30M) {
        message.error(
          `The file size should not be larger than ${maxFileSize}MB!`
        );
      }
      return isXlsx && isLt30M;
    };
    // 上传 获取formData
    const formData = new FormData();
    const curPercent = computed(() => {
      if (
        uploadStatus.value === "checked" ||
        uploadStatus.value === "imported"
      ) {
        return 100;
      } else {
        return 0;
      }
    });
    const uploadRequest = (source: any) => {
      if (formData.has("document")) {
        formData.delete("document");
      }
      formData.append("document", source.file);
      curUploadFile.value = source.file;
      uploadFileSize.value =
        source.file.size / 1024 > 1024
          ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
          : `${Math.floor(source.file.size / 1024)} KB`;
      uploadStatus.value = "beforeUpload";
    };
    // 校验上传文件
    const checkUploadFile = () => {
      const _formData = new FormData();
      _formData.append("multipartFiles", formData.get("document") as Blob);
      axios({
        method: "post",
        data: _formData,
        url: `/rvapi/task/docData/upload/data/standard`,
      })
        .then((res: any) => {
          uploadStatus.value = "checked";
        })
        .catch(() => {
          uploadStatus.value = "start";
        });
    };
    // 查询拆分数量
    const getSplitNum = () => {
      return axios({
        method: "post",
        data: formData,
        url: `/rvapi/task/docData/upload/data/dealerNum?headerId=${props.id}`,
      });
    };
    // 判断文件是否可以拆分
    const splitModalvisible = ref(false);
    const splitDealerNum = ref();
    const checkSplit = () => {
      axios({
        method: "post",
        data: formData,
        url: `/rvapi/task/docData/upload/data/isSplit?headerId=${props.id}`,
      }).then((res: any) => {
        if (res.data.data === 1) {
          getSplitNum().then((res: any) => {
            splitModalvisible.value = true;
            splitDealerNum.value = res.data.data;
            uploadStatus.value = "splited";
          });
        } else {
          message.error("Split failure");
        }
      });
    };
    const uploadFile = () => {
      axios({
        method: "post",
        data: formData,
        url: `/rvapi/task/docData/upload/data?headerId=${props.id}`,
      })
        .then((res) => {
          uploadStatus.value = 'imported'
        })
        .catch(() => {
          uploadStatus.value = "start";
        });
    };
    const handleUpload = () => {
      reuploadFile(props.id).then(() => {
        splitModalvisible.value = false;
        uploadFile();
      });
    };
    const handleNext = () => {
      _visible.value = false
      context.emit('next')
    }
    return {
      _visible,
      uploadStatus,
      curPercent,
      curUploadFile,
      uploadFileSize,
      beforeUpload,
      uploadRequest,
      checkUploadFile,
      checkSplit,
      splitModalvisible,
      splitDealerNum,
      handleUpload,
      uploadFile,
      handleDownloadTemplate,
      handleNext
    };
  },
  components: {
     CheckCircleOutlined
  }
});
